import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/labs/labs.mbanq.io/src/components/blog/Post.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`PeerCycle and Co.`}</h1>
    <p>{`We had a blast sponsoring and helping to organize the `}<a parentName="p" {...{
        "href": "https://www.f10.ch/fintech-hackathon/singapore-2019/"
      }}>{`F10 FinTech Hackathon`}</a>{` that took place in Septermber 13th-15th 2019 in Singapore - the home of `}<a parentName="p" {...{
        "href": "https://labs.mbanq.io"
      }}>{`Mbanq Labs`}</a>{`.`}</p>
    <p>{`Over 2.5 days we met great entrepreneurs and hopefully could help them to refine their great ideas and build their prototypes to showcase them.`}</p>
    <p>{`It was truly a great experience both for `}<strong parentName="p">{`Mbanq Labs`}</strong>{` as the new, hot accelerator in town and the participants that really excelled and gave their best trying to solve hard problems regular people are facing on a daily basis in banking and payments.`}</p>
    <p>{`We at Mbanq truly believe that banking should help people achieve their dreams and solve the problems we're facing every day. We offered the startups to solve the problem of international payments by utilizing the existing social networks and payment rails.`}</p>
    <p style={{
      "textAlign": "center"
    }}>
  <img src="/blog/f10-hackathon/mbanq-challenge.jpeg" alt="Mbanq Challenge" width="750px" />
    </p>
    <p>{`The competition between the six teams that took up our challenge was tough. With many high quality and innovative ideas on how to solve the issue.`}</p>
    <p>{`The winning team, `}<strong parentName="p">{`PeerCycle`}</strong>{`, came up with an innovative way to recycle money and cut out sending money overseas.`}</p>
    <p>{`The guys from `}<strong parentName="p">{`PeerCycle`}</strong>{` did an incredible job both at product design,
building the MVP and pitching.`}</p>
    <p>{`We're very happy that they won `}<strong parentName="p">{`3 out of 8`}</strong>{` awards - how cool is that!!!`}</p>
    <p>{`We offered every startup that were solving the `}<strong parentName="p">{`Mbanq's Challenge`}</strong>{` the place
in the next `}<a parentName="p" {...{
        "href": "https://labs.mbanq.io/applcation/startups"
      }}>{`Mbanq Labs`}</a>{` batch and we're really looking forward to working with them and bringing their ideas to life.`}</p>
    <p style={{
      "textAlign": "center"
    }}>
  <img src="/blog/f10-hackathon/mbanq-labs.jpeg" alt="Mbanq and Mbanq Labs
Teams - United" />
    </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      